<template>
  <v-card
    color="background"
    class="py-2 px-2"
    rounded="xl"
  >
    <v-card-text class="defaultText--text font-weight-medium">
      <div class="d-flex justify-space-between">
        <span class="big-text">{{ $t('components.shared.liquidity') }}</span>
        <span class="medium-text">{{ $n(totalBalance, 'floatCurrency') }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Liquidity',
  props: {
    totalBalance: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>

<style scoped>
.big-text {
  font-size: 24px;
}
.medium-text {
  font-size: 20px;
}
@media (max-width: 600px) {
  .big-text {
    font-size: 20px;
  }
  .medium-text {
    font-size: 16px;
  }
}
</style>
